import { closeIcon, SVGReactComponents } from '@assembly-web/assets';
import { withoutDefaultEventBehavior } from '@assembly-web/services';
import { motion } from 'framer-motion';
import { forwardRef, type InputHTMLAttributes } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

const messages = defineMessages({
  clearSearch: {
    defaultMessage: 'Clear search',
    id: '4YJHut',
  },
  searchPlaceholder: {
    defaultMessage: 'Search posts, replies and more...',
    id: 'qJOAg5',
  },
});

type GlobalSearchInputProps = InputHTMLAttributes<HTMLInputElement> & {
  hideClearButton?: boolean;
  searchTerm: string;
  onClearClick: () => void;
};

export const GlobalSearchInput = forwardRef<
  HTMLInputElement,
  GlobalSearchInputProps
>((props, inputRef) => {
  const { formatMessage } = useIntl();
  const {
    searchTerm,
    onClearClick,
    className,
    hideClearButton,
    ...inputProps
  } = props;

  return (
    <motion.div className="h-fit w-full">
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-4 flex items-center">
          <SVGReactComponents.SearchIcon className="h-5 w-5 text-gray-8" />
        </div>

        <input
          className={twMerge(
            'w-full rounded-md border-gray-5 py-2 placeholder:text-gray-7 disabled:cursor-not-allowed disabled:border disabled:border-gray-5 disabled:bg-gray-2 disabled:text-gray-7',

            'h-10 pl-[42px] pr-9',
            'transition-all',
            className
          )}
          value={searchTerm}
          aria-label={formatMessage(messages.searchPlaceholder)}
          type="text"
          ref={inputRef}
          {...inputProps}
          placeholder={formatMessage(messages.searchPlaceholder)}
          {...inputProps}
        />

        {Boolean(searchTerm) && !hideClearButton && (
          <div className="absolute inset-y-0 right-[14px] my-[1px] flex items-center pl-3">
            <button
              aria-label={formatMessage(messages.clearSearch)}
              type="button"
              className="z-[1] flex h-full cursor-pointer items-center justify-center p-2"
              onClick={withoutDefaultEventBehavior(onClearClick)}
              data-testid="clearSearch"
            >
              <img src={closeIcon} alt="" />
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
});

GlobalSearchInput.displayName = 'GlobalSearchInput';
