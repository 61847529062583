import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { getUserDetailsQuery } from '../queries/getUserDetailsQuery';
import type { MemberAPIResponse } from '../types/domain';

export function useUserDetails(options?: UseQueryOptions<MemberAPIResponse>) {
  return useQuery({
    ...options,
    ...getUserDetailsQuery(options),
  });
}

export function useSuspenseUserDetails(
  options?: UseQueryOptions<MemberAPIResponse>
) {
  return useSuspenseQuery({
    ...options,
    ...getUserDetailsQuery(options),
  });
}
