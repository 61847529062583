import { SVGReactComponents } from '@assembly-web/assets';
import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  isTruthy,
} from '@assembly-web/services';
import { GiftIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import type { Dayjs } from 'dayjs';
import type { PropsWithChildren, ReactNode } from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';

import { ProgressBar } from '../../../DesignSystem/Feedback/ProgressBar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { mapHexCodeToEmoticon } from '../PostCard/PostCardHeader';

const messages = defineMessages({
  toSpend: {
    defaultMessage:
      '<label>To redeem</label><value>{currencyIcon} {earnedBalance}</value>',
    id: 'zbM/KH',
  },
  toGive: {
    defaultMessage:
      '<label>To give</label><value>{currencyIcon} {remainingAllowance}</value>',
    id: 'WekoaN',
  },
  progress: {
    defaultMessage: '{spentAllowance}/{totalAllowance} Given',
    id: 'krdOtW',
  },
  browseRewards: {
    defaultMessage: 'Browse rewards',
    id: '9oFXbF',
  },
  pointsExpiringSoon: {
    defaultMessage:
      'Points to give expire in {noOfDays, plural, one {{noOfDays, number} day} other {{noOfDays, number} days}}',
    id: 'MkYGR7',
  },
  heading: {
    defaultMessage: 'My {currency}',
    id: 'KjdnaW',
  },
});

export type PointsWidgetProps = {
  currentDate: Dayjs;
  canRedeem: boolean;
  currency: AssemblyCurrency;
  earnedBalance: number;
  hasAllowance: boolean;
  hasEarnedPoints: boolean;
  remainingAllowance: number;
  spentAllowance: number;
  totalAllowance: number;
  variant: 'compact' | 'full';
  onBrowseRewardsClick?: () => void;
  navigateToGiveRecognition: () => void;
};

function PointsItemWrapper({ children }: PropsWithChildren) {
  return (
    <div className="inline-grid w-full grid-cols-[10ch,1fr] items-center rounded-lg bg-gray-3 p-2 @[300px]/root:grid-cols-[20ch,1fr] @[600px]/root:grid-cols-[25ch,1fr] @[650px]/root:grid-cols-[30ch,1fr]">
      {children}
    </div>
  );
}

export function PointsWidget({
  currentDate,
  canRedeem,
  currency,
  earnedBalance,
  hasAllowance,
  hasEarnedPoints,
  remainingAllowance,
  spentAllowance,
  totalAllowance,
  variant,
  onBrowseRewardsClick,
  navigateToGiveRecognition,
}: PointsWidgetProps) {
  const percentage = parseInt(`${(spentAllowance / totalAllowance) * 100}`);
  const { formatMessage } = useIntl();

  const isFullVersion = variant === 'full';

  const currencyIcon =
    currency.type === AssemblyCurrencyType.Custom ? (
      <img
        alt={currency.name}
        className="mr-0.5 h-3 w-3"
        src={currency.value}
      />
    ) : (
      mapHexCodeToEmoticon(currency.value)
    );

  const lastDayOfMonth = currentDate.endOf('month');

  const noOfDaysTillPointsExpiry = lastDayOfMonth.diff(currentDate, 'day');

  const isPointsExpiringSoon = noOfDaysTillPointsExpiry <= 7;

  return (
    <div className="flex w-full flex-col gap-2 rounded-2xl bg-gray-1 p-4 shadow-md-down @container/root">
      <TextStyle variant="xl-medium" className="pb-3 text-gray-9">
        {formatMessage(messages.heading, {
          currency: currency.pluralName,
        })}
      </TextStyle>
      <div className="flex w-full flex-col items-start justify-start gap-4">
        {isTruthy(hasAllowance) && (
          <PointsItemWrapper>
            {formatMessage(messages.toGive, {
              currencyIcon,
              remainingAllowance: (
                <FormattedNumber value={remainingAllowance} />
              ),
              label: (text: ReactNode) => (
                <TextStyle
                  className="text-gray-9"
                  variant={isFullVersion ? 'base-regular' : 'sm-regular'}
                >
                  {text}
                </TextStyle>
              ),
              value: (text: ReactNode) => (
                <div className="flex items-center justify-between">
                  <TextStyle
                    variant={isFullVersion ? 'lg-medium' : 'base-medium'}
                    className="text-right text-gray-9"
                  >
                    {text}
                  </TextStyle>
                  {isTruthy(onBrowseRewardsClick) &&
                    isTruthy(canRedeem && hasEarnedPoints) && (
                      <Tooltip
                        tooltipText={
                          <FormattedMessage
                            defaultMessage="Give recognition"
                            id="7HU4UR"
                          />
                        }
                      >
                        <IconButton
                          size="small"
                          className="text-gray-1"
                          onClick={navigateToGiveRecognition}
                        >
                          <SVGReactComponents.RecognitionIcon stroke="#fff" />
                        </IconButton>
                      </Tooltip>
                    )}
                </div>
              ),
            })}
          </PointsItemWrapper>
        )}
        {isTruthy(hasEarnedPoints) && (
          <PointsItemWrapper>
            {formatMessage(messages.toSpend, {
              currencyIcon,
              earnedBalance: <FormattedNumber value={earnedBalance} />,
              label: (text: ReactNode) => (
                <TextStyle
                  className="text-gray-9"
                  variant={isFullVersion ? 'base-regular' : 'sm-regular'}
                >
                  {text}
                </TextStyle>
              ),
              value: (text: ReactNode) => (
                <div className="flex items-center justify-between">
                  <TextStyle
                    variant={isFullVersion ? 'lg-medium' : 'base-medium'}
                    className="text-right text-gray-9"
                  >
                    {text}
                  </TextStyle>
                  {isTruthy(onBrowseRewardsClick) &&
                    isTruthy(canRedeem && hasEarnedPoints) && (
                      <Tooltip
                        tooltipText={
                          <FormattedMessage
                            defaultMessage="Browse rewards"
                            id="9oFXbF"
                          />
                        }
                      >
                        <IconButton
                          size="small"
                          variation="secondaryEmphasized"
                          onClick={onBrowseRewardsClick}
                        >
                          <GiftIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    )}
                </div>
              ),
            })}
          </PointsItemWrapper>
        )}
      </div>
      {isTruthy(hasAllowance) && (
        <>
          <ProgressBar
            progress={percentage}
            aria-label={`${spentAllowance}/${totalAllowance}`}
            indicatorBgColor={percentage >= 100 ? 'bg-error-6' : undefined}
            className="w-full min-w-[unset]"
          />
          <div className="w-full text-right">
            <TextStyle variant="sm-regular" className="text-gray-9">
              {formatMessage(messages.progress, {
                spentAllowance: <FormattedNumber value={spentAllowance} />,
                totalAllowance: <FormattedNumber value={totalAllowance} />,
              })}
            </TextStyle>
          </div>
        </>
      )}
      {isTruthy(hasEarnedPoints) && isTruthy(isPointsExpiringSoon) && (
        <div className="inline-flex w-full flex-col items-center justify-start gap-3">
          <div className="inline-flex w-full items-start justify-start gap-1 rounded bg-upgrade-2 px-2 py-1.5">
            <div className="flex items-start justify-start gap-2.5 py-0.5">
              <InformationCircleIcon className="h-4 w-4 text-upgrade-6" />
            </div>
            <TextStyle variant="xs-regular">
              {formatMessage(messages.pointsExpiringSoon, {
                noOfDays: noOfDaysTillPointsExpiry,
              })}
            </TextStyle>
          </div>
        </div>
      )}
    </div>
  );
}
