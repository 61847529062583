import type { FlowPostResponse, VisibilityTypes } from '@assembly-web/services';
import { formatToLocalDateTime, MemberState } from '@assembly-web/services';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import {
  EyeIcon,
  EyeSlashIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import type { BoostOptions } from '../../Web/Editors/RepliesEditor/RepliesEditor';
import { AuthorButton } from '../AuthorButton';
import type { PostCardProps } from './PostCardUI';
import { PostCardActions } from './PostCardUI';

export const getVisibilityIcon = (visibility: VisibilityTypes) => {
  if (visibility === 'custom' || visibility === 'onlyParticipants') {
    return <UserGroupIcon />;
  }
  if (visibility === 'private' || visibility === 'onlyOwners') {
    return <EyeSlashIcon />;
  }
  return <EyeIcon />;
};

export const mapHexCodeToEmoticon = (value: string) => {
  try {
    if (value.indexOf('-') > 0) {
      return String.fromCodePoint(
        ...value.split('-').map((item) => Number(`0x${item}`))
      );
    }

    return String.fromCodePoint(Number(`0x${value}`));
  } catch (e) {
    return value;
  }
};

const messages = defineMessages({
  postedIn: {
    defaultMessage: 'posted in',
    id: '19BOio',
  },
  ago: {
    defaultMessage: '{timeCreatedAt}',
    id: 'NhSH4V',
  },
  edited: {
    defaultMessage: 'Edited',
    id: 'OhIqLr',
  },
  anonymous: {
    defaultMessage: 'Anonymous',
    id: 'LXxsbk',
  },
  deactivatedUser: {
    defaultMessage: '(deactivated)',
    id: 'osOx6w',
  },
  customTooltip: {
    defaultMessage: 'Visible to all flow members.',
    id: 'xUCpzp',
  },
  publicTooltip: {
    defaultMessage: 'Visible to everyone in your company.',
    id: 'tR85n9',
  },
  privateTooltip: {
    defaultMessage:
      'Posted privately. Visible to admins, recipients, and mentioned people.',
    id: 'drmFb5',
  },
});

export type PostCardHeaderProps = {
  respondent: FlowPostResponse['respondent'];
  flowName: FlowPostResponse['flow']['name'];
  createdAt: FlowPostResponse['createdAt'];
  visibility: FlowPostResponse['visibility'];
  flowIcon: FlowPostResponse['flow']['icon']['value'];
  onPostCardHeaderClick: PostCardProps['onPostCardHeaderClick'];
  flowId: string;
  isEdited: boolean;
  showFlowDetails?: boolean;
  openViewInsightsModal?: () => void;
  toolbar?: ReactNode;
  contentClassName?: string;
  boostOptions?: BoostOptions;
};

export function PostCardHeader({
  boostOptions,
  respondent,
  flowName,
  createdAt,
  visibility,
  flowIcon,
  onPostCardHeaderClick,
  openViewInsightsModal,
  flowId,
  isEdited,
  showFlowDetails = true,
  toolbar,
  contentClassName,
}: PostCardHeaderProps) {
  const { formatMessage } = useIntl();

  const getTooltipText = (visibility: VisibilityTypes) => {
    if (visibility === 'custom' || visibility === 'onlyParticipants') {
      return formatMessage(messages.customTooltip);
    }
    if (visibility === 'private' || visibility === 'onlyOwners') {
      return formatMessage(messages.privateTooltip);
    }
    return formatMessage(messages.publicTooltip);
  };

  return (
    <header className="flex justify-between">
      <div className="flex">
        {respondent === null ? (
          <div className="h-10 w-10 rounded-full bg-error-2" />
        ) : (
          <Avatar
            size={AvatarSize.Large}
            image={respondent.image}
            name={respondent.firstName}
            memberID={respondent.memberID}
            className={twJoin(
              respondent.memberState === MemberState.Deactivated && 'grayscale'
            )}
          />
        )}
        <div className="flex flex-col justify-between pl-2">
          <div className="grid grid-cols-[auto_auto_1fr] align-baseline text-sm font-normal">
            <AuthorButton
              boostOptions={boostOptions}
              respondent={respondent}
              onClick={() => {
                if (respondent !== null && respondent.memberID !== '')
                  onPostCardHeaderClick(
                    PostCardActions.PersonClicked,
                    respondent.memberID
                  );
              }}
              className={contentClassName}
            />
            {Boolean(showFlowDetails) && (
              <>
                {formatMessage(messages.postedIn)}
                <button
                  onClick={() => {
                    if (flowId)
                      onPostCardHeaderClick(
                        PostCardActions.FlowNameClicked,
                        flowId
                      );
                  }}
                  className="mx-1 text-primary-6 hover:text-primary-5"
                >
                  <TextStyle
                    className={twMerge('text-left', contentClassName)}
                    variant="sm-medium"
                  >
                    {mapHexCodeToEmoticon(flowIcon)} {flowName}
                  </TextStyle>
                </button>
              </>
            )}
          </div>
          <div className="flex items-center gap-1 align-middle text-sm text-gray-8">
            <Tooltip tooltipText={formatToLocalDateTime(createdAt)}>
              <span className="cursor-pointer">
                {formatMessage(messages.ago, {
                  timeCreatedAt: dayjs(createdAt).fromNow(),
                })}
              </span>
            </Tooltip>
            <span>•</span>
            <Tooltip tooltipText={getTooltipText(visibility)}>
              <span className="h-4 w-4">{getVisibilityIcon(visibility)}</span>
            </Tooltip>
            {Boolean(openViewInsightsModal) && (
              <>
                <span>•</span>
                <button
                  className="h-4 w-4"
                  onClick={openViewInsightsModal}
                  aria-label="View insights"
                >
                  <ChartBarIcon />
                </button>
              </>
            )}
            {Boolean(isEdited) && (
              <TextStyle variant="xs-regular" subdued>
                {formatMessage(messages.edited)}
              </TextStyle>
            )}
          </div>
        </div>
      </div>

      {toolbar}
    </header>
  );
}
