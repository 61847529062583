import { type UIMatch, useMatches } from 'react-router-dom';

export function useRecognitionRoute() {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1] as UIMatch<
    unknown,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    unknown | { route: 'recognition' }
  >;

  return (
    typeof lastMatch.handle === 'object' &&
    lastMatch.handle !== null &&
    'route' in lastMatch.handle &&
    lastMatch.handle.route === 'recognition'
  );
}
