import { withoutDefaultEventBehavior } from '@assembly-web/services';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import type { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  type ForwardRefExoticComponent,
  type FunctionComponent,
  type PropsWithoutRef,
  type ReactNode,
  type RefAttributes,
  type SVGProps,
  useMemo,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../Tooltip';

const messages = defineMessages({
  moreLabel: {
    defaultMessage: 'more',
    id: 'gk3x/E',
  },
});

export type ToolbarItem = {
  id: string;
  text: string;
  icon:
    | ForwardRefExoticComponent<
        PropsWithoutRef<SVGProps<SVGSVGElement>> & {
          title?: string;
          titleId?: string;
        } & RefAttributes<SVGSVGElement>
      >
    | FunctionComponent<
        SVGProps<SVGSVGElement> & { title?: string | undefined }
      >;
  disabled?: boolean;
  isAlertStyle?: boolean;
  tooltipText?: string;
  hasPoints?: boolean;
  replacedContent?: (onBack: () => void) => ReactNode;
};

export type ToolbarProps = {
  contentPlacement?: DropdownMenuContentProps['side'];
  variant?: 'condensed' | 'comfortable';
  triggerClassName?: string;
  className?: string;
  hideBorder?: boolean;
  isMenuOpen?: boolean;
  /** indicates whether the menu trigger looks compact or normal sized */
  compact?: boolean;
  hideToolbar: boolean;
  onClosed?: () => void;
  hideWhenDetached?: boolean;
  primaryToolbarItems?: ToolbarItem[];
  secondaryToolbarItems: ToolbarItem[];
  onToggle?: (isOpen: boolean) => void;
  onMenuItemClick: (
    args: ToolbarItem,
    setIsReplaced?: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
};

export function Toolbar({
  triggerClassName,
  variant = 'condensed',
  contentPlacement = 'bottom',
  className,
  hideBorder = false,
  compact = false,
  onClosed,
  onToggle,
  hideToolbar,
  isMenuOpen,
  onMenuItemClick,
  hideWhenDetached,
  primaryToolbarItems,
  secondaryToolbarItems,
}: ToolbarProps) {
  const { formatMessage } = useIntl();

  const wrapperPadding = primaryToolbarItems?.length === 0 ? 'p-[3px]' : 'p-1';

  if (hideToolbar) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'pointer-events-auto flex rounded-lg bg-gray-1',
        !hideBorder && 'border border-gray-5',
        wrapperPadding,
        compact
          ? 'h-6 w-fit min-w-[24px] items-center justify-center hover:bg-gray-3'
          : 'h-fit w-full',
        className
      )}
    >
      {primaryToolbarItems?.map((item) => (
        <Tooltip key={item.id} tooltipText={item.text}>
          <button
            key={item.id}
            aria-label={item.text}
            className={twMerge(
              'flex items-center justify-center rounded hover:bg-gray-3',
              compact ? 'h-4 w-4' : 'h-6 w-6',
              triggerClassName
            )}
            onClick={withoutDefaultEventBehavior(() => {
              onMenuItemClick(item);
            })}
          >
            <item.icon
              strokeWidth={2}
              className={compact ? 'h-3 w-3' : 'h-4 w-4'}
            />
          </button>
        </Tooltip>
      ))}
      {secondaryToolbarItems.length > 0 && (
        <MenuBar
          items={secondaryToolbarItems}
          variant={variant}
          contentPlacement={contentPlacement}
          isMenuOpen={isMenuOpen}
          onToggle={onToggle}
          onClosed={onClosed}
          onMenuItemClick={onMenuItemClick}
          hideWhenDetached={hideWhenDetached}
          trigger={
            <button
              title={formatMessage(messages.moreLabel)}
              aria-label={formatMessage(messages.moreLabel)}
              className={twMerge(
                'flex items-center justify-center rounded hover:bg-gray-3',
                compact ? 'h-4 w-4' : 'h-6 w-6'
              )}
            >
              <EllipsisHorizontalIcon
                className={twMerge(
                  'pointer-events-none fill-gray-10 stroke-2',
                  compact ? 'h-4 w-4' : 'h-6 w-6'
                )}
              />
            </button>
          }
        />
      )}
    </div>
  );
}

export function MenuBar({
  contentPlacement = 'bottom',
  hideWhenDetached,
  isMenuOpen,
  items,
  trigger,
  variant = 'condensed',
  onClosed,
  onMenuItemClick,
  onToggle,
}: Pick<
  ToolbarProps,
  | 'contentPlacement'
  | 'hideWhenDetached'
  | 'isMenuOpen'
  | 'variant'
  | 'onClosed'
  | 'onMenuItemClick'
  | 'onToggle'
> & {
  items: ToolbarItem[];
  trigger: ReactNode;
}) {
  const [isReplaced, setIsReplaced] = useState<string | null>(null);
  const isComfortable = variant === 'comfortable';

  const currentItem = useMemo(
    () => items.find((item) => item.id === isReplaced),
    [isReplaced, items]
  );

  const onBack = () => {
    setIsReplaced(null);
  };

  return (
    <DropdownMenu.Root
      open={isMenuOpen}
      onOpenChange={(state) => {
        onToggle?.(state);
        if (!state) {
          onClosed?.();
          setIsReplaced(null);
        }
      }}
    >
      <DropdownMenu.Trigger
        className="relative inline-block text-left"
        aria-label="ellipsis"
        asChild
        onClick={withoutDefaultEventBehavior(() => {})}
      >
        {trigger}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align={isComfortable ? 'start' : 'end'}
          alignOffset={-2}
          sideOffset={isComfortable ? 10 : 6}
          side={contentPlacement}
          className={twMerge(
            'z-[12] max-h-[32rem] w-max origin-top overflow-auto rounded-md border border-gray-5 bg-gray-1 py-1 shadow-lg-down ring-opacity-5 focus:outline-none',
            isComfortable && 'w-52 rounded-2xl',
            isReplaced && 'w-84'
          )}
          // this is intentional, added to prevent the click event from bubbling up to the parent
          onClick={withoutDefaultEventBehavior(() => {})}
          hideWhenDetached={hideWhenDetached}
        >
          {currentItem?.replacedContent ? (
            <div>{currentItem.replacedContent(onBack)}</div>
          ) : (
            items.map((action) => {
              const item = (
                <div
                  className={twMerge(
                    'bg-gray-0 flex w-full items-center gap-2 px-3 py-[9px] text-sm text-gray-8 hover:bg-gray-3 focus:outline-none',
                    isComfortable && 'px-4'
                  )}
                >
                  <action.icon
                    className={twMerge(
                      'h-4 w-4 stroke-2',
                      isComfortable && 'text-gray-8',
                      action.isAlertStyle && 'text-error-6',
                      action.disabled && 'text-gray-7'
                    )}
                  />
                  <div
                    className={twMerge(
                      'line-clamp-1 text-left',
                      isComfortable && 'font-medium text-gray-9',
                      action.isAlertStyle && 'text-error-6',
                      action.disabled && 'text-gray-7'
                    )}
                  >
                    {action.text}
                  </div>
                </div>
              );
              return (
                <DropdownMenu.Item
                  key={action.id}
                  onClick={
                    action.disabled
                      ? () => {}
                      : withoutDefaultEventBehavior(() => {
                          if (action.replacedContent) {
                            setIsReplaced(action.id);
                          } else {
                            onMenuItemClick(action);
                          }
                        })
                  }
                  className={twMerge(
                    'cursor-pointer focus:bg-primary-2 focus:outline-none',
                    action.disabled && 'cursor-default bg-gray-2'
                  )}
                  disabled={action.disabled}
                >
                  {action.tooltipText ? (
                    <Tooltip
                      tooltipText={action.tooltipText}
                      contentClassName="max-w-[232px]"
                      side="left"
                    >
                      {item}
                    </Tooltip>
                  ) : (
                    item
                  )}
                </DropdownMenu.Item>
              );
            })
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
