import { Indicator, Root } from '@radix-ui/react-progress';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import type { MergeExclusive } from 'type-fest';

type ProgressBarProps = MergeExclusive<
  { ['aria-label']: string },
  { ['aria-describedby']: string }
> & { progress: number; indicatorBgColor?: string; className?: string };

export function ProgressBar({
  progress,
  indicatorBgColor,
  className,
  ...props
}: ProgressBarProps) {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setProgressValue(progress), 1000);
    return () => clearTimeout(timer);
  }, [progress]);

  return (
    <Root
      value={progressValue}
      className={twMerge(
        'h-3 w-full min-w-[300px] overflow-hidden rounded-full bg-gray-3',
        className
      )}
      {...props}
    >
      <Indicator
        style={{ width: `${progressValue}%` }}
        className={twMerge(
          'h-full duration-300 ease-in-out',
          indicatorBgColor ? indicatorBgColor : 'bg-primary-3'
        )}
      />
    </Root>
  );
}
