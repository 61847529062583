import { withoutDefaultEventBehavior } from '@assembly-web/services';
import { MegaphoneIcon } from '@heroicons/react/24/outline';
import { Indicator, Root as ProgressRoot } from '@radix-ui/react-progress';
import { Content, List, Root, Trigger } from '@radix-ui/react-tabs';
import { cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { LoadingSpinner } from '../../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { Modal } from '../../../../DesignSystem/Feedback/Modal';
import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { classNames } from '../../../../DesignSystem/Utils/classNames';
import { ConfirmationModal } from '../ConfirmationModal';
import { getAnnouncementGradients } from './gradientColors';
import { messages } from './messages';
import { ModalTitle } from './ModalTitle';
import { CustomizeAndStyleTabContent } from './TabContent/CustomizeAndStyleTabContent';
import { DistributionTabContent } from './TabContent/DistributionTabContent';
import { SettingsAndControlsContent } from './TabContent/SettingsAndControlsTabContent';
import type {
  AnnouncementFormErrors,
  AnnouncementFormValues,
} from './useAnnouncementForm';
import { AnnouncementFormContext } from './useAnnouncementForm';

type AnnouncementTabItemProps = {
  step: number;
  label: string;
  value: string;
  progress: number;
  visited: boolean;
  isActive: boolean;
  disabled?: boolean;
};

const indicatorStyles = cva('h-full duration-300 ease-in-out bg-gray-4', {
  variants: {
    disabled: {
      true: 'bg-gray-7',
    },
    visited: {
      true: 'bg-primary-6',
    },
  },
});

const textStyles = cva('', {
  variants: {
    disabled: {
      true: 'text-gray-7',
      false: 'text-gray-9',
    },
    visited: {
      true: 'text-primary-6',
    },
  },
});

export function AnnouncementTabItem({
  step,
  value,
  label,
  visited,
  progress,
  isActive,
  disabled = false,
}: AnnouncementTabItemProps) {
  const { formatMessage } = useIntl();
  return (
    <Trigger
      value={value}
      disabled={disabled}
      className={classNames(disabled && 'cursor-not-allowed', 'flex-1')}
    >
      <div className="flex h-full flex-col items-start">
        <ProgressRoot
          value={progress}
          className={classNames(
            'mb-2 h-1 w-full overflow-hidden rounded-full bg-primary-2',
            isActive && 'ring-2 ring-primary-6 ring-offset-2'
          )}
        >
          <Indicator
            className={indicatorStyles({
              visited,
              disabled,
            })}
          />
        </ProgressRoot>
        <div className="flex flex-col items-start">
          <TextStyle
            variant="xs-medium"
            className={textStyles({
              visited,
              disabled,
            })}
          >
            {formatMessage(messages.stepLabel, { step })}
          </TextStyle>
          <TextStyle
            variant="sm-medium"
            className={classNames(
              'text-left',
              disabled ? 'text-gray-7' : 'text-gray-9'
            )}
          >
            {label}
          </TextStyle>
        </div>
      </div>
    </Trigger>
  );
}

export type AnnouncementCenterModalProps = {
  isOpen: boolean;
  memberID: string;
  onClose: () => void;
  memberFullName: string;
  isSavingAnnouncement: boolean;
  disableResponseSettings: boolean;
  onGetHelpButtonClick: () => void;
  announcementFormValues: AnnouncementFormValues;
  onSave: (value: AnnouncementFormValues) => void;
  imageUrl?: string;
  isNewUX: boolean;
};

enum Tabs {
  CustomizeAndStyle = 'customizeAndStyle',
  SettingsAndControls = 'settingsAndControls',
  Distribution = 'distribution',
}

const tabValues = Object.values(Tabs);

export const defaultFormValues: AnnouncementFormValues = {
  title: '',
  description: '',
  backgroundColor: 'gray',
  allowPostReplies: false,
  isMentionsEnabled: false,
  allowPostReactions: false,
  isPushNotificationEnabled: true,
  isEmailNotificationEnabled: true,
};

const gradients = getAnnouncementGradients();

export function AnnouncementCenterModal({
  isOpen,
  onClose,
  memberID,
  onSave,
  memberFullName,
  onGetHelpButtonClick,
  isSavingAnnouncement,
  announcementFormValues,
  disableResponseSettings,
  imageUrl,
  isNewUX,
}: AnnouncementCenterModalProps) {
  const [prevTabIndex, setPrevTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(Tabs.CustomizeAndStyle);
  const [isNavigatedInTabs, setIsNavigatedInTabs] = useState(false);
  const [visitedTabs, setVisitedTabs] = useState<string[]>([
    Tabs.CustomizeAndStyle,
  ]);

  const { formatMessage } = useIntl();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [formValues, setFormValues] = useState(announcementFormValues);
  const [formErrors, setFormErrors] = useState<AnnouncementFormErrors>({});

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const setFormValue = (
    name: keyof AnnouncementFormValues,
    value: string | boolean
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormValue('allowPostReplies', announcementFormValues.allowPostReplies);
    setFormValue(
      'allowPostReactions',
      announcementFormValues.allowPostReactions
    );
  }, [announcementFormValues]);

  const isMovingForward =
    prevTabIndex < Object.values(Tabs).indexOf(selectedTab);

  const slideVariants = isNavigatedInTabs
    ? {
        animate: {
          x: 0,
          opacity: 1,
          transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 },
        },
        exit: {
          opacity: 0,
          x: isMovingForward ? -50 : 50,
          transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 },
        },
        initial: {
          opacity: 0,
          x: isMovingForward ? 50 : -50,
          transition: { type: 'tween', ease: 'easeInOut', duration: 0.1 },
        },
      }
    : undefined;

  const navigateTab = (step: number) => {
    setIsNavigatedInTabs(true);
    const currentIndex = tabValues.indexOf(selectedTab);
    const newIndex = currentIndex + step;

    if (newIndex >= 0 && newIndex < tabValues.length) {
      setPrevTabIndex(currentIndex);
      setSelectedTab(tabValues[newIndex]);
      setVisitedTabs((prevTabs) => [...prevTabs, tabValues[newIndex]]);
    }
  };

  const handleTabChange = (value: Tabs) => {
    if (!visitedTabs.includes(value)) {
      setVisitedTabs((prevTabs) => [...prevTabs, value]);
    }
    setSelectedTab(value);
  };

  const onNextButtonClick = () => navigateTab(1);
  const onBackButtonClick = () => navigateTab(-1);

  const onCreateAnnouncementButtonClick = () => {
    if (!formValues.title.trim()) {
      setFormErrors({
        ...formErrors,
        title: formatMessage(messages.titleRequiredLabel),
      });
      setSelectedTab(Tabs.CustomizeAndStyle);
    } else {
      onSave(formValues);
    }
  };

  const backButton = selectedTab !== Tabs.CustomizeAndStyle && (
    <Button
      variation="secondaryEmphasized"
      onClick={withoutDefaultEventBehavior(onBackButtonClick)}
    >
      {formatMessage(messages.backLabel)}
    </Button>
  );

  const nextButton = selectedTab !== Tabs.Distribution && (
    <Button
      variation="primary"
      disabled={
        !formValues.title.trim() &&
        visitedTabs.length === 1 &&
        selectedTab === Tabs.CustomizeAndStyle
      }
      onClick={withoutDefaultEventBehavior(onNextButtonClick)}
    >
      {formatMessage(messages.nextLabel)}
    </Button>
  );

  const createAnnouncementButton = selectedTab === Tabs.Distribution && (
    <Button
      type="submit"
      variation="primary"
      isLoading={isSavingAnnouncement}
      onClick={withoutDefaultEventBehavior(onCreateAnnouncementButtonClick)}
    >
      {isSavingAnnouncement ? (
        <div className="flex text-gray-1">
          <LoadingSpinner className="mr-2" />
          {formatMessage(messages.savingLabel)}
        </div>
      ) : announcementFormValues.announcementId ? (
        formatMessage(messages.saveAnnouncementLabel)
      ) : (
        formatMessage(messages.createAnnouncementLabel)
      )}
    </Button>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!isFormDirty || announcementFormValues.announcementId) {
          onClose();
        } else {
          setIsConfirmationModalOpen(true);
        }
      }}
      className={twJoin(
        'flex flex-col md:w-[576px] md:max-w-xl',
        isNewUX
          ? 'h-[min(calc(100vh-16px),600px)]'
          : 'h-[min(calc(100vh-16px),660px)]'
      )}
      headerClassName="border-b border-gray-4"
      bodyClassName="!p-0 flex flex-col overflow-hidden flex-1"
      closeButtonSize="large"
      title={
        <ModalTitle
          icon={
            <MegaphoneIcon
              className="h-6 w-6"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth={1.5}
            />
          }
          title={formatMessage(messages.announcementCenterLabel)}
        />
      }
    >
      <AnnouncementFormContext.Provider
        value={{ formValues, formErrors, setFormValue, setFormErrors }}
      >
        <form
          onChange={() => {
            setIsFormDirty(true);
          }}
          className={twJoin(
            'flex flex-1 flex-col overflow-scroll px-6',
            isNewUX ? 'pb-4' : 'pb-7'
          )}
        >
          <Root
            value={selectedTab}
            className="TabsRoot"
            onValueChange={(value) => handleTabChange(value as Tabs)}
          >
            <List className="flex gap-6 py-4">
              <AnnouncementTabItem
                step={1}
                progress={100}
                value={Tabs.CustomizeAndStyle}
                isActive={selectedTab === Tabs.CustomizeAndStyle}
                visited={visitedTabs.includes(Tabs.CustomizeAndStyle)}
                label={formatMessage(messages.customizeAndStyleLabel)}
              />
              <AnnouncementTabItem
                step={2}
                progress={100}
                value={Tabs.SettingsAndControls}
                isActive={selectedTab === Tabs.SettingsAndControls}
                disabled={!formValues.title && visitedTabs.length === 1}
                visited={visitedTabs.includes(Tabs.SettingsAndControls)}
                label={formatMessage(messages.settingsAndControlsLabel)}
              />
              <AnnouncementTabItem
                step={3}
                progress={100}
                value={Tabs.Distribution}
                isActive={selectedTab === Tabs.Distribution}
                visited={visitedTabs.includes(Tabs.Distribution)}
                label={formatMessage(messages.distributionLabel)}
                disabled={!formValues.title && visitedTabs.length === 1}
              />
            </List>
            <Content className="TabsContent" value={Tabs.CustomizeAndStyle}>
              <AnimatePresence>
                <motion.div
                  key="CustomizeAndStyle"
                  variants={slideVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <CustomizeAndStyleTabContent
                    isNewUX={isNewUX}
                    memberID={memberID}
                    memberFullName={memberFullName}
                    selectedGradient={
                      gradients.find(
                        (x) => x.name === formValues.backgroundColor
                      ) ?? gradients[0]
                    }
                    setSelectedGradient={(gradient) => {
                      setFormValue('backgroundColor', gradient.name);
                    }}
                    imageUrl={imageUrl}
                  />
                </motion.div>
              </AnimatePresence>
            </Content>
            <Content className="TabsContent" value={Tabs.SettingsAndControls}>
              <AnimatePresence>
                <motion.div
                  key="CustomizeAndStyle"
                  variants={slideVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <SettingsAndControlsContent
                    disableResponseSettings={disableResponseSettings}
                  />
                </motion.div>
              </AnimatePresence>
            </Content>
            <Content className="TabsContent" value={Tabs.Distribution}>
              <AnimatePresence>
                <motion.div
                  key="CustomizeAndStyle"
                  variants={slideVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <DistributionTabContent />
                </motion.div>
              </AnimatePresence>
            </Content>
          </Root>
        </form>
        <footer className="flex flex-shrink-0 justify-between border-t border-gray-4 p-4">
          <Button
            type="button"
            variation="secondaryLite"
            onClick={withoutDefaultEventBehavior(onGetHelpButtonClick)}
          >
            {formatMessage(messages.getHelpLabel)}
          </Button>

          <div className="flex gap-2">
            {backButton}
            {nextButton}
            {createAnnouncementButton}
          </div>
        </footer>
      </AnnouncementFormContext.Provider>
      <ConfirmationModal
        label={formatMessage(messages.confirmationModalLabel)}
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        title={formatMessage(messages.confirmationModalTitle)}
        description={formatMessage(messages.confirmationModalDescription)}
        leftButton={
          <Button
            isFullWidth
            variation="secondaryLite"
            onClick={() => setIsConfirmationModalOpen(false)}
          >
            {formatMessage(messages.cancelLabel)}
          </Button>
        }
        rightButton={
          <Button isFullWidth variation="danger" onClick={onClose}>
            {formatMessage(messages.leaveAnnouncementLabel)}
          </Button>
        }
      />
    </Modal>
  );
}
