import {
  Item as ToggleGroupItem,
  Root as ToggleGroup,
} from '@radix-ui/react-toggle-group';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { TextField } from '../../../../../DesignSystem/Inputs/TextField';
import { selectIcon } from '../../../../assets/icons';
import { AnnouncementCard } from '../../../Cards/AnnouncementCard';
import type { Gradient } from '../gradientColors';
import { getAnnouncementGradients } from '../gradientColors';
import { messages } from '../messages';
import { useAnnouncementForm } from '../useAnnouncementForm';

type CustomizeAndStyleTabContentProps = {
  memberID: string;
  memberFullName: string;
  selectedGradient: Gradient;
  setSelectedGradient: (gradient: Gradient) => void;
  imageUrl?: string;
  isNewUX: boolean;
};

export function CustomizeAndStyleTabContent({
  memberID,
  memberFullName,
  selectedGradient,
  setSelectedGradient,
  imageUrl,
  isNewUX,
}: CustomizeAndStyleTabContentProps) {
  const { formValues, setFormValue, formErrors, setFormErrors } =
    useAnnouncementForm();
  const { formatMessage } = useIntl();
  const [isTitleTouched, setIsTitleTouched] = useState(false);

  const validateTitle = (title: string) => {
    let errorMessage = '';

    if (!title.trim()) {
      errorMessage = formatMessage(messages.titleRequiredLabel);
    } else if (title.length > 45) {
      errorMessage = formatMessage(messages.titleTooLongLabel);
    }

    setFormErrors({
      ...formErrors,
      title: errorMessage,
    });
  };

  return (
    <section className="flex flex-col gap-4">
      <TextField
        inputSize="md"
        autoComplete="off"
        value={formValues.title}
        labelClassName="font-medium text-sm"
        isInvalid={Boolean(formErrors.title)}
        label={formatMessage(messages.titleLabel)}
        helpText={formatMessage(messages.titleHintLabel)}
        invalidText={formErrors.title ? formErrors.title : ''}
        placeholder={formatMessage(messages.placeholderLabelForTitle)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value.length <= 45) {
            setFormValue('title', e.target.value);
          }
          if (isTitleTouched) {
            validateTitle(formValues.title);
          }
        }}
        onBlur={() => {
          setIsTitleTouched(true);
          validateTitle(formValues.title);
        }}
        connectedRight={
          Boolean(formValues.title) && (
            <TextStyle variant="sm-medium" className="-mr-2 mt-16 text-gray-9">
              {formValues.title.length}/45
            </TextStyle>
          )
        }
      />

      <label className="text-sm font-medium">
        {formatMessage(messages.descriptionLabel)}
      </label>
      <textarea
        rows={3}
        value={formValues.description}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setFormValue('description', e.target.value)
        }
        placeholder={formatMessage(messages.placeholderLabelForDescription)}
        className="-mt-4 w-full resize-none rounded-lg border-gray-5 px-3 py-2 text-gray-9 placeholder:text-gray-7 focus:border-primary-6 focus:ring-primary-6"
      />

      <section>
        <label className="text-sm font-medium text-gray-9">
          {formatMessage(messages.announcementThumbnailLabel)}
        </label>
        <div className="flex flex-col-reverse gap-3 md:flex-row md:items-center">
          <AnnouncementCard
            isNewUX={isNewUX}
            hideMenu
            gradient={selectedGradient}
            title={
              formValues.title
                ? formValues.title
                : formatMessage(messages.placeholderLabelForTitle)
            }
            memberID={memberID}
            fullName={memberFullName}
            description={
              formValues.description
                ? formValues.description
                : formatMessage(messages.placeholderLabelForDescription)
            }
            imageUrl={imageUrl}
          />

          <div className="md:-mt-4">
            <TextStyle variant="sm-regular">
              {formatMessage(messages.chooseBackgroundColorLabel)}
            </TextStyle>
            <ToggleGroup
              className="flex flex-wrap gap-3"
              type="single"
              orientation="horizontal"
              onValueChange={(value) => {
                const gradient = getAnnouncementGradients().find(
                  (gradient) => gradient.name === value
                );
                if (!gradient) return;
                setSelectedGradient(gradient);
                setFormValue('backgroundColor', gradient.name);
              }}
              defaultValue={getAnnouncementGradients()[0].name}
            >
              {getAnnouncementGradients().map((gradient) => (
                <ToggleGroupItem
                  key={gradient.name}
                  value={gradient.name}
                  className={twJoin(
                    'rounded-md',
                    'hover:border-gray-8',
                    gradient.className,
                    isNewUX ? 'h-8 w-8' : 'h-10 w-10'
                  )}
                >
                  <div
                    className={
                      selectedGradient.name === gradient.name
                        ? 'block'
                        : 'invisible'
                    }
                  >
                    <img
                      alt=""
                      src={selectIcon}
                      role="presentation"
                      className="h-fit w-fit"
                    />
                  </div>
                </ToggleGroupItem>
              ))}
            </ToggleGroup>
          </div>
        </div>
      </section>
    </section>
  );
}
