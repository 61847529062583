const legacyExperiencePathsWithinV3 = [
  '/user',
  '/flows',
  '/flows/recognition/answer',
  '/settings',
  '/template',
] as const;

const legacyExperiencePaths = [
  ...legacyExperiencePathsWithinV3,
  '/post',
  '/people',
  '/notebook',
  '/knowledge-center',
  '/create-one-click-flow',
  '/rewards',
  '/home',
] as const;

const newExperiencePaths = [
  '/discover',
  '/templates', // this is v3 experience path which shows the templates container,once picked a template it will redirect to /template
  '/connections',
  ...legacyExperiencePaths,
] as const;

const onboardingPaths = [
  '/join',
  '/login',
  '/ms-teams',
  '/templates',
  '/onboarding',
  '/workspaces',
  '/sso-sign-in',
  '/verify-email',
  '/create-account',
  '/account-invite',
  '/sso-account-creation',
] as const;

const publicPaths = ['/templates', '/e'] as const;

export const routeConstants = {
  legacyExperiencePathsWithinV3,
  legacyExperiencePaths,
  newExperiencePaths,
  onboardingPaths,
  publicPaths,
};
