import { BellIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import * as MenuBar from '@radix-ui/react-menubar';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { motion } from 'framer-motion';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { useDevice } from '../../hooks/useDevice';

export type GlobalFiltersProps = {
  notificationsCount?: number;
  showRewardsTab: boolean;
  aggregations?: Record<GlobalFilterOption, number> | undefined;
  selectedFilter: GlobalFilterOption;
  onFilterChange: (filter: GlobalFilterOption) => void;
  showChallengesTab: boolean;
  hideAggregationsValue?: boolean;
  isNewUX: boolean;
};

const paddingAroundFilterButtonsContainer = 64;
const maxWidthOfFilterButtonWithoutCount = 110;
const maxWidthOfFilterButtonWithCount: Record<number, number> = {
  1: 120,
  2: 125,
  3: 130,
};

const filtersSectionMessages = defineMessages({
  moreFilter: {
    defaultMessage: 'More',
    id: 'I5NMJ8',
  },
  allFilter: {
    defaultMessage: 'My Feed',
    id: 'QVYuxu',
  },
  filesFilter: {
    defaultMessage: 'Files',
    id: 'm4vqJl',
  },
  tasksFilter: {
    defaultMessage: 'Tasks',
    id: 'yhU1et',
  },
  flowsFilter: {
    defaultMessage: 'Flows',
    id: '3Pbj9T',
  },
  peopleFilter: {
    defaultMessage: 'People',
    id: 'Tpy00S',
  },
  rewardsFilter: {
    defaultMessage: 'Rewards',
    id: 'y0EYpw',
  },
  collectionsFilter: {
    defaultMessage: 'Collections',
    id: 'ulh3kf',
  },
  foldersFilter: {
    defaultMessage: 'Folders',
    id: '8ILyTU',
  },
  challengesFilter: {
    defaultMessage: 'Challenges',
    id: '///zLu',
  },
});

export enum GlobalFilterOption {
  Notifications = 'notifications',
  All = 'all',
  Collections = 'collections',
  Files = 'files',
  Flows = 'flows',
  People = 'people',
  Rewards = 'rewards',
  Tasks = 'tasks',
  Challenges = 'challenges',
  Recognition = 'recognition',
}

export function GlobalFilter({
  notificationsCount = 0,
  selectedFilter,
  aggregations,
  onFilterChange,
  showRewardsTab,
  showChallengesTab,
  hideAggregationsValue,
  isNewUX,
}: GlobalFiltersProps) {
  const { formatMessage } = useIntl();

  const stickyFiltersBreakpoint = showRewardsTab ? 690 : 596;

  const [isCondensed, setIsCondensed] = useState(false);
  const [noOfCondensedOptionsToBeShown, setNoOfCondensedOptionsToBeShown] =
    useState(0);

  const formFactor = useDevice();

  const handleResize = useCallback(() => {
    const filterContainerLeftPosition = Number.parseInt(
      document
        .getElementById('global-filters')
        ?.style.marginLeft.replace('px', '') ?? '0'
    );

    const bufferSpaceForOtherElementsInHeader = 200;

    const newLocal =
      (filterContainerLeftPosition +
        (stickyFiltersRef.current?.clientWidth ?? 0) || 0) +
      bufferSpaceForOtherElementsInHeader;

    const showMoreButton =
      (stickyFiltersRef.current &&
        stickyFiltersRef.current.clientWidth < stickyFiltersBreakpoint) ||
      document.documentElement.clientWidth < newLocal;

    if (stickyFiltersRef.current && showMoreButton) {
      setIsCondensed(true);

      const hasCountText = Object.values(aggregations ?? {}).some(
        (count: unknown) => typeof count === 'number' && count > 0
      );

      const maxCount = Math.max(
        ...Object.values(aggregations ?? {}).map((count: unknown) =>
          typeof count === 'number' ? count : 0
        )
      );

      const widthOfEachButton = hasCountText
        ? maxWidthOfFilterButtonWithCount[maxCount > 3 ? 3 : maxCount]
        : maxWidthOfFilterButtonWithoutCount;

      const noOfButtons =
        Math.floor(
          (stickyFiltersRef.current.clientWidth -
            paddingAroundFilterButtonsContainer) /
            widthOfEachButton
        ) - 1;
      setNoOfCondensedOptionsToBeShown(noOfButtons < 0 ? 0 : noOfButtons);
    } else {
      setIsCondensed(false);
      setNoOfCondensedOptionsToBeShown(0);
    }
  }, [aggregations, stickyFiltersBreakpoint]);

  const stickyFiltersRef = useRef<HTMLDivElement>(null);
  const prevPosition = useRef(0);
  const [showGradient, setShowGradient] = useState(false);

  const handleScroll = useCallback(() => {
    if (stickyFiltersRef.current) {
      const currentPosition = window.scrollY;

      if (currentPosition < prevPosition.current) {
        const filtersRect = stickyFiltersRef.current.getBoundingClientRect();
        setShowGradient(filtersRect.y === 56);
        if (currentPosition > 400) {
          // scroll up, show filters
          stickyFiltersRef.current.style.top = '56px'; // header height - filter top padding
        }
      }
      prevPosition.current = currentPosition;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, handleScroll]);

  const filterOptions = useMemo(() => {
    const allFilters = [
      {
        label: formatMessage(filtersSectionMessages.allFilter),
        value: GlobalFilterOption.All,
      },
      ...(showChallengesTab
        ? [
            {
              label: formatMessage(filtersSectionMessages.challengesFilter),
              value: GlobalFilterOption.Challenges,
            },
          ]
        : []),
      {
        label: formatMessage(
          isNewUX
            ? filtersSectionMessages.foldersFilter
            : filtersSectionMessages.collectionsFilter
        ),
        value: GlobalFilterOption.Collections,
      },
      {
        label: formatMessage(filtersSectionMessages.flowsFilter),
        value: GlobalFilterOption.Flows,
      },
      {
        label: formatMessage(filtersSectionMessages.filesFilter),
        value: GlobalFilterOption.Files,
      },
      {
        label: formatMessage(filtersSectionMessages.peopleFilter),
        value: GlobalFilterOption.People,
      },
    ];

    if (showRewardsTab) {
      allFilters.push({
        label: formatMessage(filtersSectionMessages.rewardsFilter),
        value: GlobalFilterOption.Rewards,
      });
    }

    return allFilters;
  }, [formatMessage, isNewUX, showChallengesTab, showRewardsTab]);

  const filters = useMemo(() => {
    const allFilters = filterOptions;

    const selectedFilterFromList = allFilters.find(
      (current) => current.value === selectedFilter
    );

    // condensedFilters represents the filter options displayed as a dropdown
    // displayFilters includes individual filter options to be shown based on the provided parameters
    // selectedFilter is the current filter selected that is present as a search param in the URL.
    // selectedFilter is displayed as a separate option beside the displayFilters.
    // Applicable only for responsive view

    // Depending on how many items can be shown on responsive view, without going to a new line
    // we show the first few items as individual options using shownFilters
    const shownFilters = allFilters
      .slice(0, noOfCondensedOptionsToBeShown)
      .filter((current) => current.value !== selectedFilterFromList?.value);

    // The rest of the items are shown as a dropdown in condensed format
    const condensedFilters = allFilters
      .slice(noOfCondensedOptionsToBeShown, allFilters.length)
      .filter((current) => current.value !== selectedFilterFromList?.value);

    return {
      displayFilters: isCondensed
        ? [
            // Always show ALL filter in the beginning
            ...(selectedFilterFromList?.value === 'all'
              ? [selectedFilterFromList]
              : []),
            ...shownFilters,
            ...(selectedFilterFromList && selectedFilterFromList.value !== 'all'
              ? [selectedFilterFromList]
              : []),
          ]
        : allFilters,
      condensedFilters: condensedFilters,
    };
  }, [
    selectedFilter,
    isCondensed,
    noOfCondensedOptionsToBeShown,
    filterOptions,
  ]);

  const gradientClasses = showGradient
    ? 'from-gray-1 to-transparent after:absolute after:-bottom-4 after:left-4 after:h-4 after:w-[calc(100%-2rem)] after:bg-gradient-to-b lg:after:left-[88px] lg:after:w-[calc(100%-11rem)]'
    : 'rounded-lg';

  const displayFiltersInline = formFactor === 'xs' || formFactor === 'sm';

  return (
    <motion.section
      id="global-filters"
      ref={stickyFiltersRef}
      className={twMerge(
        'pl-4 md:pl-0',
        displayFiltersInline
          ? 'overflow-x-auto'
          : 'md:w-[500px] lg:w-[600px] xl:w-[900px]'
      )}
    >
      <div
        className={twMerge(
          gradientClasses,
          displayFiltersInline ? 'w-max' : 'w-full'
        )}
      >
        <ToggleGroup.Root
          className="flex text-base font-medium"
          value={selectedFilter}
          onValueChange={(value) => {
            // Toggle group allows us to deselect an item, so we need to prevent that
            if (value) {
              onFilterChange(value as GlobalFilterOption);
            }
          }}
          orientation="horizontal"
          type="single"
        >
          <ToggleGroup.Item
            data-testid="discover-filter-button-notifications"
            id="discover-filter-button-notifications"
            value={GlobalFilterOption.Notifications}
            className={twMerge(
              'mx-1 inline-flex max-h-8 items-center gap-1 whitespace-nowrap rounded-lg border-[1px] px-2 py-1 text-center text-gray-9 first:ml-0',
              'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
              'data-[state=off]:border-gray-5 data-[state=off]:hover:bg-gray-3',
              'data-[state=on]:border-gray-9 data-[state=on]:bg-gray-9 data-[state=on]:text-gray-1'
            )}
          >
            <BellIcon className="h-5 w-5" />
            {notificationsCount > 0 && (
              <div
                className={twMerge(
                  'inline-flex h-5 items-center justify-center gap-2 rounded-3xl px-2',
                  selectedFilter === GlobalFilterOption.Notifications
                    ? 'bg-gray-8'
                    : 'bg-error-6'
                )}
              >
                <div className="text-xs font-bold text-gray-1">
                  {notificationsCount > 9 ? '9+' : notificationsCount}
                </div>
              </div>
            )}
          </ToggleGroup.Item>

          {(displayFiltersInline ? filterOptions : filters.displayFilters).map(
            ({ value, label }, _, array) => (
              <Fragment key={value}>
                <ToggleStyledTab
                  data-testid={`discover-filter-button-${value}`}
                  id={`discover-filter-button-${value}`}
                  value={value}
                  className={twMerge(
                    'mx-1 inline-flex max-h-8 items-center gap-1 whitespace-nowrap rounded-lg border-[1px] px-3 py-1 text-center text-gray-9 first:ml-0',
                    'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
                    'data-[state=off]:border-gray-5 data-[state=off]:hover:bg-gray-3',
                    'data-[state=on]:border-gray-9 data-[state=on]:bg-gray-9 data-[state=on]:text-gray-1'
                  )}
                >
                  {label}
                  {aggregations && !hideAggregationsValue ? (
                    <TextStyle variant="base-regular" as="span">
                      {` ${aggregations[value]}`}
                    </TextStyle>
                  ) : null}
                </ToggleStyledTab>
                {value === GlobalFilterOption.All && array.length >= 2 && (
                  <div
                    className="mt-1 h-6 w-[1px] rounded-lg bg-gray-5"
                    data-size={array.length}
                  ></div>
                )}
              </Fragment>
            )
          )}

          {!displayFiltersInline && Boolean(isCondensed) && (
            <MenuBar.Root>
              <MenuBar.Menu>
                <MenuBar.Trigger
                  className={twMerge(
                    'mx-1 ml-2 flex items-center gap-2 rounded-lg border-[1px] px-3 py-1 text-gray-9',
                    'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
                    'data-[state=closed]:border-gray-5 data-[state=closed]:hover:bg-gray-3',
                    'data-[state=open]:border-gray-7 data-[state=open]:bg-gray-9 data-[state=open]:text-gray-1'
                  )}
                >
                  {formatMessage(filtersSectionMessages.moreFilter)}
                  <ChevronDownIcon className="h-4 w-4 group-aria-expanded:rotate-180" />
                </MenuBar.Trigger>
                <MenuBar.Content
                  className="space-b shadow-md z-1 absolute left-0 z-10 mt-1 max-h-[32rem] w-32 origin-top transform overflow-auto rounded-md border border-gray-5 bg-gray-1 py-1 shadow-lg-down ring-opacity-5 focus:outline-none"
                  align="start"
                  sideOffset={5}
                  alignOffset={-3}
                >
                  {filters.condensedFilters.map(({ value, label }) => (
                    <MenuBar.Item
                      data-testid={`discover-condensed-filter-menu-item-${value}`}
                      key={value}
                      onSelect={() => onFilterChange(value)}
                      className="cursor-pointer focus:bg-primary-2 focus:outline-none"
                    >
                      <button className="bg-gray-0 flex w-full cursor-pointer items-center justify-between p-[9px] pr-2 text-sm text-gray-8 hover:bg-gray-3 focus:outline-none">
                        {label}
                      </button>
                    </MenuBar.Item>
                  ))}
                </MenuBar.Content>
              </MenuBar.Menu>
            </MenuBar.Root>
          )}
        </ToggleGroup.Root>
      </div>
    </motion.section>
  );
}

export const ToggleStyledTab = (
  props: ToggleGroup.ToggleGroupItemProps &
    React.RefAttributes<HTMLButtonElement>
) => {
  const { children, disabled, ...rest } = props;
  return (
    <ToggleGroup.Item
      className={twMerge(
        'mx-1 inline-flex max-h-8 items-center gap-1 whitespace-nowrap rounded-lg border-[1px] px-3 py-1 text-center text-gray-9 first:ml-0',
        'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
        'data-[state=off]:border-gray-5 data-[state=off]:hover:bg-gray-3',
        'data-[state=on]:border-gray-9 data-[state=on]:bg-gray-9 data-[state=on]:text-gray-1',
        disabled && 'cursor-not-allowed bg-gray-4 hover:bg-gray-4'
      )}
      disabled={disabled}
      {...rest}
    >
      {props.children}
    </ToggleGroup.Item>
  );
};
