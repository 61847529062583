import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { classNames } from '../Utils/classNames';

export type SelectProps = {
  label: string;
  name: string;
  classNames?: string;
  triggerClassNames?: string;
  hiddenLabel?: boolean;
  options: unknown[];
  selected?: unknown;
  isInvalid?: boolean;
  getText: (value: unknown) => string;
  getValue: (value: unknown) => string | number | undefined;
  onChange?: (value: unknown) => void;
  disabled?: boolean;
  position?: 'top' | 'bottom';
};

export function Select(props: SelectProps) {
  const position = props.position ?? 'bottom';
  const [selected, setSelected] = useState(props.selected ?? props.options[0]);

  return (
    <Listbox
      value={selected}
      disabled={props.disabled}
      onChange={(value) => {
        setSelected(value);
        props.onChange?.(value);
      }}
    >
      {({ open }) => (
        <div
          className={twMerge(
            props.classNames,
            props.disabled && 'cursor-not-allowed opacity-60'
          )}
        >
          <input
            name={props.name}
            value={props.getValue(selected)}
            hidden
            readOnly
          />
          <Listbox.Label
            className={classNames(
              'block text-sm font-medium text-neutral-primary',
              {
                'sr-only': props.hiddenLabel,
              }
            )}
          >
            {props.label}
          </Listbox.Label>
          <div className={classNames('relative mt-1')}>
            <Listbox.Button
              className={classNames(
                'relative w-full cursor-default rounded-md border border-gray-6 bg-gray-1 py-2 pl-3 pr-10 text-left shadow-sm-down focus:border-primary-6 focus:outline-none focus:ring-1 focus:ring-primary-6 sm:text-sm',
                props.triggerClassNames,
                { 'border-error-6': props.isInvalid }
              )}
            >
              <span className="block truncate">{props.getText(selected)}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-7"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className={classNames(
                  'absolute z-10 w-full overflow-auto rounded-md bg-gray-1 py-1 text-base shadow-lg-down ring-1 ring-gray-10 ring-opacity-5 focus:outline-none sm:text-sm',
                  position === 'top' ? 'bottom-full mb-1' : 'top-full mt-1',
                  'max-h-60'
                )}
              >
                {props.options.map((option) => (
                  <Listbox.Option
                    key={props.getValue(option)}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'bg-primary-6 text-gray-1'
                          : 'text-neutral-primary',
                        'group relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {props.getText(option)}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-gray-1' : 'text-primary-6',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
