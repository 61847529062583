import * as RadioGroup from '@radix-ui/react-radio-group';
import {
  Children,
  isValidElement,
  type ReactElement,
  type ReactNode,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../Feedback/TextStyle';

function Title({ children }: { children: ReactNode }) {
  return <TextStyle variant="sm-medium">{children}</TextStyle>;
}

function Subtitle({ children }: { children: ReactNode }) {
  return (
    <TextStyle variant="xs-regular" className="text-gray-8">
      {children}
    </TextStyle>
  );
}

export function Root({
  className,
  children,
  value,
  onValueChange,
  direction = 'row',
  disabled = false,
}: {
  value: string;
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  direction?: 'row' | 'column';
  onValueChange?: (value: string) => void;
}) {
  return (
    <RadioGroup.Root
      disabled={disabled}
      className={twMerge(
        'flex flex-col gap-2 lg:flex-row',
        direction === 'column' && 'flex-col',
        className
      )}
      value={value}
      onValueChange={onValueChange}
    >
      {children}
    </RadioGroup.Root>
  );
}

export function Item({
  value,
  children,
}: {
  value: string;
  children: ReactNode;
}) {
  let titleElement: ReactElement | null = null;
  let subtitleElement: ReactElement | null = null;

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === Title) {
        titleElement = child;
      } else if (child.type === Subtitle) {
        subtitleElement = child;
      }
    }
  });

  return (
    <RadioGroup.Item
      value={value}
      className="group flex min-w-56 cursor-pointer items-center justify-between gap-2 rounded-lg border border-gray-5 px-4 py-3 data-[state=checked]:border-primary-6 data-[state=checked]:bg-primary-1 data-[state=unchecked]:bg-gray-1 hover:data-[state=checked]:bg-primary-2 hover:data-[state=unchecked]:bg-gray-3"
    >
      <section className="flex flex-col items-start">
        {<div>{titleElement}</div>}
        {<div>{subtitleElement}</div>}
      </section>
      <div className="h-4 w-4 rounded-full focus:ring-offset-2 focus:ring-offset-gray-1 group-data-[state=checked]:bg-primary-6 group-data-[state=unchecked]:ring-1 group-data-[state=unchecked]:ring-inset group-data-[state=unchecked]:ring-gray-6 group-data-[state=checked]:focus:ring-2 group-data-[state=checked]:focus:ring-primary-6">
        <RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:h-1.5 after:w-1.5 after:rounded-full after:bg-gray-1" />
      </div>
    </RadioGroup.Item>
  );
}

export const ChoiceBox = {
  Root,
  Item,
  Title,
  Subtitle,
};
